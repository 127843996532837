import { CacheProvider } from '@emotion/react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline, NoSsr } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Head from 'next/head';
import React, { Suspense } from 'react';
import ErrorBoundary from 'src/components/error-boundary';
import Loading from 'src/components/loading';
import { GlobalStorage } from 'src/context/GlobalStorage';
import '../services/bugsnag';
import { theme } from '../theme';
import { createEmotionCache } from '../utils/create-emotion-cache';
import { SnackbarProvider } from 'notistack';

const clientSideEmotionCache = createEmotionCache();

const App = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps, err } = props;

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <GlobalStorage>
      <CacheProvider value={emotionCache}>
        <SnackbarProvider>
          <Head>
            <title>Plin Pass</title>
            <meta name='viewport' content='initial-scale=1, width=device-width' />
          </Head>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <ErrorBoundary>
                <NoSsr>
                  <Suspense fallback={<Loading />}>{getLayout(<Component {...pageProps} err={err} />)}</Suspense>
                </NoSsr>
              </ErrorBoundary>
            </ThemeProvider>
          </LocalizationProvider>
        </SnackbarProvider>
      </CacheProvider>
    </GlobalStorage>
  );
};

export default App;
