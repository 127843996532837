import React from 'react';

export const GlobalContext = React.createContext();

export const GlobalStorage = ({ children }) => {
  const [tipoPessoa, setTipoPessoa] = React.useState(null);
  const [meio, setMeio] = React.useState(null);
  const [modalidadesVenda, setModalidadesVenda] = React.useState(null);
  const [idEvento, setIdEvento] = React.useState(null);
  const [idEquipe, setIdEquipe] = React.useState(null);
  const [idCardapio, setIdCardapio] = React.useState(null);
  const [idProduto, setIdProduto] = React.useState(null);
  const [idLocal, setIdLocal] = React.useState(null);
  const [productsRows, setProductsRows] = React.useState([]);
  const [nomeCardapio, setNomeCardapio] = React.useState('');
  const [idItem, setIdItem] = React.useState(null);
  const [abaItemVoltar, setAbaItemVoltar] = React.useState(null);
  const [valorVoltar, setValorVoltar] = React.useState('');
  const [formEdita, setFormEdita] = React.useState([]);
  const [itemNovo, setItemNovo] = React.useState(null);
  const [idItemNovo, setIdItemNovo] = React.useState(null);
  const [productsList, setProductsList] = React.useState();
  const [nomeCozinhaEvento, setNomeCozinhaEvento] = React.useState('');
  const [tipoCozinhaEvento, setTipoCozinhaEvento] = React.useState(null);
  const [itensCozinhaCardapioEvento, setItensCozinhaCardapioEvento] = React.useState(null);
  const [idCozinhaEditaEvento, setIdCozinhaEditaEvento] = React.useState(null);
  const [modalIdDelete, setModalIdDelete] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [nextLink, setNextLink] = React.useState(null);
  const [idFornecedor, setIdFornecedor] = React.useState(false);

  return (
    <GlobalContext.Provider
      value={{
        tipoPessoa,
        meio,
        modalidadesVenda,
        idEvento,
        setTipoPessoa,
        setMeio,
        setModalidadesVenda,
        setIdEvento,
        idEquipe,
        setIdEquipe,
        idCardapio,
        setIdCardapio,
        idProduto,
        setIdProduto,
        idLocal,
        setIdLocal,
        productsRows,
        setProductsRows,
        nomeCardapio,
        setNomeCardapio,
        idItem,
        setIdItem,
        abaItemVoltar,
        setAbaItemVoltar,
        valorVoltar,
        setValorVoltar,
        formEdita,
        setFormEdita,
        itemNovo,
        setItemNovo,
        idItemNovo,
        setIdItemNovo,
        productsList,
        setProductsList,
        nomeCozinhaEvento,
        setNomeCozinhaEvento,
        tipoCozinhaEvento,
        setTipoCozinhaEvento,
        itensCozinhaCardapioEvento,
        setItensCozinhaCardapioEvento,
        idCozinhaEditaEvento,
        setIdCozinhaEditaEvento,
        modalIdDelete,
        setModalIdDelete,
        openModal,
        setOpenModal,
        nextLink,
        setNextLink,
        idFornecedor,
        setIdFornecedor,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
