import { Alert, AlertTitle, Box } from '@mui/material';
import axios from 'axios';
import React, { Component } from 'react';

export default class ErrorBoundary extends Component {
  state = {};

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (!this.state.error) return this.props.children;

    return <Erro sx={this.props.sx} error={this.state.error} />;
  }
}

function Erro({ title, error, sx }) {
  return (
    <Box sx={sx}>
      <Alert severity='error'>
        <AlertTitle>{title || 'Erro'}</AlertTitle>
        {traduzMensagem(error)}
      </Alert>
    </Box>
  );
}

function traduzMensagem(e) {
  if (typeof e === 'string') return e;

  if (axios.isAxiosError(e)) {
    if (e.response?.status === 403) return 'Você não tem permissão para acessar este recurso.';
    if (e.response?.status === 404) return 'Informações não encontradas.';
    if (e.response?.status === 500) return 'Erro ao comunicar com a API.';
  }

  if (isError(e)) return e.message;

  return JSON.stringify(e);
}

function isError(e) {
  return e instanceof Error || !!(e && typeof e === 'object' && 'message' in e);
}
